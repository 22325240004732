import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import Work from '../Work';


export default function Services(){
    return <>

    <div className='services'>
        <h1 className='name' >SERVICES</h1>
    
    
    </div>
    

    <Work />
    <Footer />
    </>
}
