import React from 'react';
import WorkItem from './WorkItem';
import './Work.css';

function Work() {
    
  return (
    <div className='work'>
        <h1>Check Out Our Previous Services</h1>
        <div className="work__container">
            <div className="work__wrapper">
                <ul className='work__items'>
                    <WorkItem
                    src='images/living.jpg'
                    text='We provide thorough general house cleaning, covering surfaces, high-touch areas, and more. Using eco-friendly products, We create a refreshed, healthy living space tailored to your needs.'
                    label='Fumigation'
                    path='/services'
                    />
                </ul>
                <ul className='work__items'>   
                    <WorkItem
                    src='images/kitchen.jpg'
                    text='We excel in specialized kitchen cleaning, meticulously tackling surfaces, appliances, and hard-to-reach areas. Using effective, safe cleaning solutions, We ensure a pristine and hygienic kitchen environment tailored to your preferences.'
                    label='Cleaning'
                    path='/services'
                    />
                </ul>
                <ul className='work__items'>
                    <WorkItem
                    src='images/living2.jpg'
                    text='We bring a dedicated touch to living room cleaning, focusing on detailed dusting, upholstery care, and meticulous floor maintenance. '
                    label='Gardening'
                    path='/services'
                    /> 
                </ul>
                <ul className='work__items'>
                    <WorkItem
                    src='images/matress.jpg'
                    text='Your mattress will be rejuvenated, providing a cleaner and healthier environment for a restful nights sleep.'
                    label='Gardening'
                    path='/services'
                    />
                    </ul>
                <ul className='work__items'>
                    <WorkItem
                    src='images/img-11.jpg'
                    text='Our landscaping expertise transforms outdoor spaces into captivating havens. From vibrant plant arrangements to meticulous lawn care, We create harmonious landscapes tailored to your vision'
                    label='Matress Cleaning '
                    path='/services'
                    />
                    </ul>
                <ul className='work__items'>
                <WorkItem
                    src='images/office.jpeg'
                    text='In the heart of Nairobi, We undertook a transformative office cleaning project that revitalized the workspace. Focused on maintaining a professional and inviting atmosphere, We implemented a meticulous cleaning regimen. '
                    label='Gardening'
                    path='/services'
                    />
                </ul>
                <ul className='work__items'>
                <WorkItem
                    src='images/office2.jpg'
                    text='In the bustling business district of Nairobi, We undertook a dynamic office cleaning project that elevated the professional environment.'
                    label='Matress Cleaning  '
                    path='/services'
                    />
                </ul>
                <ul className='work__items'>
                <WorkItem
                    src='images/land.jpeg'
                    text='In the upscale neighborhood of Runda, We embarked on a captivating landscaping project that redefined outdoor aesthetics. Collaborating closely with the client, We curated a tailored design, blending lush greenery with colorful blooms. '
                    label='Matress Cleaning  '
                    path='/services'
                    />
                </ul>
            </div>
        </div>
    </div>
  )
}

export default Work;