import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/pages/Home';
import Services from './components/pages/Services';
import Aboutus from './components/pages/Aboutus';


function App() {
  return (
    <>
    <Router>
      <Navbar/>
      <Routes>
        <Route path='/' exact element= {<Home/>}/>
        <Route path='/services' exact element = {<Services/>}/>
        <Route path='/' exact element= {<Home/>}/>
        <Route path='/aboutus' exact element= {<Aboutus/>}/>
      </Routes>
    </Router>
    {/* WhatsApp icon */}
    <a
        href="https://wa.me/+254727935705"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon"></i>
      </a>
    </>
     
  );
}

export default App;
