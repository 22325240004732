import React from 'react';
import CardItem from './CardItem';
import'./Cards.css';

function Cards() {
    
  return (
    <div className='cards'>
        <h1>Check Out My Previous Services</h1>
        <div className="cards__container">
            <div className="cards__wrapper">
                <ul className='cards__items'>
                    <CardItem 
                    src='images/img-12.jpg'
                    text='Fumigation services in Nairobi'
                    label='Fumigation'
                    path='/services'
                    
                    />
                     <CardItem 
                    src='images/img-15.jpeg'
                    text='Matress Cleaning in Nairobi'
                    label='Matress Cleaning '
                    path='/services'
                    />
                </ul>
                <ul className='cards__items'>
                     <CardItem 
                    src='images/img-11.jpg'
                    text='Landscaping Services in Nairobi'
                    label='Landscaping'
                    path='/services'
                    />
                     <CardItem 
                    src='images/img-13.png'
                    text='General Cleaning services in Nairobi'
                    label='House Cleaning '
                    path='/services'
                    />
                </ul>
            </div>
        </div>
    </div>
  );
}

export default Cards;