import React from 'react';
import '../App.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
function Team() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 200,
        slidesToShow: 2,
        slidesToScroll: 2
      };
  return (
    <div className="great">
        <div className='hands'>
        <Slider {...settings}>
          {data.map((d) =>(
            <div className="big"> 
                <div className="inner">
                 <img src={d.img} className="img" alt="img-team"/>
                </div>
                <div className='review'>
                    <p className='head'>{d.name}</p>
                    <p className='feet'>{d.review}</p>

                </div>
             </div>
           ))}
           </Slider>
        </div>


    </div>
  );
}
const data =[
    { 
        name:`John doe`,
        img: `/images/img-6.jpg`,
        review: `good working employyee the acconuntatnt in our team`

    },
    { 
        name:`sophie `,
        img: `/images/img-5.jpg`,
        review: `good working employyee the acconuntatnt in our team`

    },
    { 
        name:`allan`,
        img: `/images/img-2.jpg`,
        review: `good working employyee the acconuntatnt in our team`

    },{ 
        name:`mary `,
        img: `/images/img-1.jpg`,
        review: `good working employyee the acconuntatnt in our team`

    },{ 
        name:` otieno `,
        img: `/images/img-5.jpg`,
        review: `good working employyee the acconuntatnt in our team`

    }
]

export default Team;