import React from 'react';
import '../components/home-comp/Whyus.css';
import { Link } from 'react-router-dom';


function Uitem(props) {
  return (
    <>
       <li className="us__item">
        <Link className='uslink' >
             <ul>
                <div className='us-icon' >
                <i class={props.icon}></i>
            
                </div>
                <div className='us-head'>
                    <h5>{props.head}</h5>
                
                </div>
                <div className='us-imp-text'>
                {props.imptext}
                </div>
             </ul>
        </Link>  
       </li>
    </>
  );
}

export default Uitem;