import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import Cards from '../Cards';
import Footer from '../Footer';
import Text from '../home-comp/Text';
import Top from '../home-comp/Top';
import Whyus from '../home-comp/Whyus';



function Home(){
 return(
    <>
    <HeroSection />
    <Top />
    <Cards />
    <Whyus />
    <Text />
    <Footer />
    
    </>
 );

}

export default Home;
