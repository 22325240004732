import React from 'react';
import '../home-comp/Whyus.css';
import Uitem from '../Uitem';



function Whyus() {
  return (
    <div className='imp'>
    <div className='why'>
        
        <div className='heading'>
      <h2>Why Choose Us</h2>
        </div>
        <div className='us'> 
            <div className='us-col'>
       <ul>
          <Uitem 
          icon="fa-solid fa-user-tie"
          head="Professionalism"
          imptext="Our uniformed and courteous staff is dedicated to providing you with a professional cleaning experience. Trust us to handle your space with the utmost respect and attention it deserves."
          /> 
           <Uitem 
          icon="fa-solid fa-rotate-right"
          head="Reliability and Consistency"
          imptext="Count on us for reliable and consistent cleaning services. Punctuality is our promise, and we ensure that each visit maintains the high standards you expect. Your satisfaction is our top priority."
          />
          <Uitem 
          icon="fa-solid fa-sliders"
          head="Customized Services"
          imptext="Our cleaning services are tailored to your specific needs. Whether it's a cozy home or a bustling office, we've got the right cleaning solution for you."
          />
           <Uitem 
          icon="fa-solid fa-ranking-star"
          head="Quality Assurance"
          imptext="Our commitment to quality is evident in every service we provide. From eco-friendly products to advanced cleaning techniques, we guarantee a spotless result every time."
          /> 
  
          <Uitem 
          icon="fa-solid fa-hand-holding-dollar"
          head="Transparent Pricing"
          imptext="No hidden fees, no surprises – just transparent pricing! Explore our straightforward pricing structure that clearly outlines the services included. Request a quote easily online and know exactly what to expect."
          />
</ul>
<div className="test"></div>
          </div>
     
         </div>
         
    </div>
    </div>
  );
}

export default Whyus;