import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import '../Work.css';
import image from "../images/download1.png";
import Card from '../Card';
import Team from '../Team';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";


export default function Aboutus(){
  
    return <>

    <div className='aboutus'>
        
        <h1 className='name'>ABOUT US</h1>
        </div>
<div className='all'>
        <section
      style={{
        fontFamily:'revert-layer',
        alignItems: 'center',
        textAlign:'center',
        justifyContent:'center',
        fontSize: "1rem",
        fontWeight: 1.5,
        lineHeight: 1.5,
        color: "#292b2c",
        background: "linear-gradient(#fff,#f5adad)",
        padding: "0 2em",
        display:'flex',
        flexFlow:"column"
      }}
    >
      <div
        style={{
          textAlign: "center",
          maxWidth: "900px",
          margin: "10 auto",
          border: "1px solid #e6e6e6",
          padding: "70px 20px",
          marginTop: "50px"
        }}
      >
        <img
          src={image}
          alt="Anold From Ahadi Cleaners"
          style={{
            margin: "-60px 20px 20px",
            width: "150px",
            borderRadius: "50%",
            objectFit: "cover",
            marginBottom: "20px"
          }}
        />
        <div>
          <p
            style={{
              lineHeight: 1.5,
              fontWeight: 300,
              marginBottom: "25px",
              fontSize: "1.375rem"
            }}
          >
            My name is Anold, and I take pride in being the meticulous owner of Ahadi Cleaners. Back in 2010, I decided to turn my passion for cleanliness into a thriving business. At Ahadi Cleaners, we're all about attention to detail, reliability, and trustworthiness.
I believe in a hands-on approach to management, ensuring that our services consistently meet the highest standards. What sets Ahadi Cleaners apart is our commitment to eco-friendly practices—because a clean environment shouldn't come at the expense of the planet.
 </p>
        </div>
        <p
          style={{
            marginBottom: "0",
            fontWeight: 600,
            fontSize: "1rem"
          }}
        >
          Anold 
          <span style={{ fontWeight: 400 }}> · Owner of Ahadi Cleaners</span>
        </p>
      </div>
    </section>
    <section className='team'>
      <div className='my-team'>
        <div className='team'><h3>My team</h3>
        </div>
        
      <div className='in'>
        <Team />
     
          </div>
          
          </div>
          <div className='large'>
            <div className='mid4'>
            <h6>I’m currently available for Cleaning and fumigation work.</h6>
            </div>
            <div className='mid5'>
              <h5>If you’re looking for a Cleaner and Fumigation expert that likes to get stuff done, let’s talk.</h5>
            </div>

          </div>
          <div className='goals'>
              <h3 className='h-goal'>My Goals</h3>
              <div className='my-goals'>

              <div className='goal-1'> 
                 <h6> Exceed client Expectations:</h6>
                 <p className='par'> Strive to go above and beyond in
                   every cleaning task, ensuring clients 
                   are not just satisfied but genuinely 
                   impressed with the results.</p>
              </div>
              <div className='goal-2'>
                <h6> Maintain Meticulous Standards:</h6>
                <p className='par'> Uphold a commitment to attention to
                   detail, leaving no corner untouched.
                    A clean and pristine environment is 
                    my signature..</p>
              </div>
              <div className='goal-3'>
                <h6> Prioritize Reliability:</h6>
                <p className='par '> Be a trustworthy partner for clients,
                   consistently delivering services on 
                   time and as promised. Reliability is 
                   the foundation of a strong client-provider
                    relationship.</p>
              </div>

            </div>
          </div>
          <section className='review'>
            <h1 className='h-goals'> Customer reviews</h1>
            <div className='view'>
              <Card 
              title="Alex"
              image="/images/alex2.png"
              description="Absolutely delighted with the exceptional cleaning services! My living space has never looked better—impeccably clean, organized, and a true testament to their attention to detail. I couldn't be happier with the outstanding job they've done!"
              /> 
              <Card 
              title="Maria"
              image="/images/img-8.jpg"
              description="I am beyond impressed with the top-notch cleaning services provided. My home has been transformed into a spotless haven, and the meticulous care taken by the team is truly commendable. Thank you for making every corner shine"
              />

            </div>
            

          </section>
          
      
     </section>
    
</div> <Footer />
    
    </>
}
