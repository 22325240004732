import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import { Button2 } from './Button2';


function HeroSection() {
  return (
    <div className='hero-container'>
        <video src="/videos/video-3.mp4" autoPlay loop muted />
        <h1>Sparkle with us!</h1>
        <p>Reach out to us</p>
        <div className="hero-btns">

        <Button 
        className='btns' 
        buttonStyle='btn--outline'
         buttonSize='large' >Our Services
         </Button>
        <Button2
         className='btns' 
         buttonStyle='btn--primary' 
         buttonSize='large' >About Ahadi Cleaners
         <i className='far fa-play-circle/'></i> 
         </Button2>
        </div>
    </div>
  );
}

export default HeroSection;