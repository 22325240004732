import React from 'react';
import '../components/Cards.css';


function Card({title,image,description}) {
  return (
    <div className='card'>
        <div className='card-mid'>
      <img src={image} className='img-card' alt={title}/>
        <h2 className='title-card'>{title}</h2>
        <p className='des-card'>{description}</p>
      </div>
    </div>
  )
};

export default Card;