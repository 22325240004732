import React from 'react';
import './Text.css';
import '../Work.css';

import Card from '../Card';

function Text() {
  return (
    <div className='main' >
      
        <div className='small-main'>
           <h2>Let's embark on a journey to cleanliness together!</h2>
        </div>
        <section className='review'>
            <h1 className='h-goals'> Customer review</h1>
            <div className='view'>
              <Card 
              title="Alex"
              image="/images/alex2.png"
              description="Absolutely delighted with the exceptional cleaning services! My living space has never looked better—impeccably clean, organized, and a true testament to their attention to detail. I couldn't be happier with the outstanding job they've done!"
              /> 

            </div>
            

          </section>
        <div className='large-main'>
           <h1>If you're seeking a cleaning service that gets the job done efficiently and effectively, let's start a conversation.</h1>
        </div>
        <div></div>
    </div>

  )
};

export default Text;