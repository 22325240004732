import React,{useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Button } from './Button';
import './Navbar.css';
//<img src={process.env.PUBLIC_URL + "/images/logo.png"} width="150" alt="logo"/>
function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);


  const handleClick = () => setClick(!click);
  const closeMobileMenu = () =>setClick(false);
  const scrollToBottom = () => {
      window.scrollTo(0,document.body.scrollHeight);
   
  }
  const scrollToTop = () => {
    window.scrollTo(0 ,0);
 
}
  const showButton = () => {
  if(window.innerWidth <= 960){
  setButton(false);
    }else{
  setButton(true);
    }
  };
  
  useEffect(() =>{
    showButton();
  },[]
  );

  window.addEventListener('resize', showButton);
  return (
    <>
    <nav className ="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-logo" onClick= {closeMobileMenu}>
        <i class="fa-solid fa-spray-can-sparkles"></i> AHADI CLEANERS 
        </Link>
        <div className="menu-icon" onClick={handleClick} >
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>
        <ul className= {click ?'nav-menu active' :'nav-menu'}>
          <li classname='nav-item'>
            <Link to='/' className='' onClick={closeMobileMenu}>
              <Link to='/' className='nav-links' onClick={scrollToTop} >
              Home
              </Link>
            </Link>
          </li>
          <li classname='nav-item'>
            <Link to='/services' className='' onClick={closeMobileMenu}>
              <Link to='/services' className='nav-links' onClick={scrollToTop} >
              Services
              </Link>
            </Link>
          </li>
          <li classname='nav-item'>
            <Link to='/aboutus' className='' onClick={closeMobileMenu}>
            <Link to='/aboutus' className='nav-links' onClick={scrollToTop} >
            About us
              </Link>
              
            </Link>
          </li>
          <li classname='nav-item'>
            <Link to='/' className='' onClick={closeMobileMenu} >
             <Link to='/' className='nav-links-mobile' onClick={scrollToBottom} >
             Contact
              </Link>
            </Link>
          </li>
        </ul>
        {button && <Button buttonStyle='btn--outline'>Contact</Button>}
      </div>
    </nav>
    </>
  )
}

export default Navbar;
// Day 1 - 41:26 React website tutorial