import React from 'react';
import { Link } from 'react-router-dom';


function WorkItem(props) {
  const scrollToBottom = () => {
    window.scrollTo(0,document.body.scrollHeight);
 
}
  return (
    <>
       <li className="work__item">
        <Link onClick={scrollToBottom} className="work__item__link" to={props.path} >
        <figure className="cards__item__pic-wrap" >
            <img src={props.src} alt="team-image"
            className="work__item__img"/>
         </figure>
            <div className="work__item__info">
                <h5 className="work__item__text">{props.text}</h5>
            </div>
        </Link>  
       </li>
    </>
  );
}

export default WorkItem;