import React from 'react';
import './Top.css';


function Top() {
  return (
    <div>
        <div className='main1' >
           <section className='sec-main'>
            <div className='medium-main'>
             <h2>Hey there, this is Ahadi Cleaners.</h2>
             <h2></h2>

           </div>
           <div className='l-main'>
             <h1>landscaping, Pest control, Home and Office Cleaning Services</h1>
           </div>
           <div className='text'>
            <div>Welcome to Ahadi Cleaners, where pristine spaces and impeccable hygiene converge. We are your go-to partner for professional cleaning and fumigation services, dedicated to elevating your surroundings to a new standard of cleanliness and well-being.</div>
           </div>
           </section>
           <div className='info'>
            <div>
            <a href="/" target="blank" >see my work <i class="fa-solid fa-download"></i></a>
            </div>
           </div>
        </div>
    </div>
  )
}

export default Top