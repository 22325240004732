import React from 'react';
//import { Button } from './Button';
import { Link } from 'react-router-dom';
import './Footer.css';


function Footer() {
  const scrollToTop = () => {
    window.scrollTo(0 ,0);

    
  }
  const scrollToTest = () => {
    window.scrollTo(0 ,3350);
 
}
const scrollToTeam = () => {
  window.scrollTo(0 ,1750);

}
  return (
    <div className='foot-container'> 
       <section className='Contact-info'>
        <h1 className='footer-contact-heading'>
            Want to Reach out to Us?
        </h1>
        
        <div className='info'>
        <i class="fa-solid fa-phone" ></i>
           
        <div className='icon'><a href="tel:+254 727 935705" > 
              <h2>+254 727 935705</h2>
          </a></div>
       
            
        </div>
        <p className='footer-contact-text'>
            Please enter you Email and write up a message to us
        </p>
        <div className='input-areas'>
            <form name="submit" action="https://formsubmit.co/ahadicleaners@gmail.com" method="POST" target="blank">
                <input 
                type='text'
                name='message'
                placeholder='Write up your message'
                className='footer-message'
                />

                <input 
                type='email'
                name='email'
                placeholder='Your Email'
                className='footer-input'
                />
                <button className='btn1' buttonStyle='btn--outline' >Send Email</button>
                
            </form>
        </div>
       </section>
       <div className='footer-links'>
        <div className='footer-links-wrapper'>
         <div className='footer-links-items' >
            <h2>About</h2>
            <Link to="/aboutus" onClick={scrollToTop}>How I Work</Link>
            <Link to="/aboutus" onClick={scrollToTest}>Testimonials</Link>
            <Link to="/aboutus" onClick={scrollToTeam}>My Team</Link>
           </div>

        </div>
        <div className='footer-links-items' >
            <h2>Social Media</h2>
            <a href="https://www.instagram.com/ahadi_cleaners/" target="blank">Instagram</a>
            <a href="https://wa.me/+254727935705" target="blank" >WhatsApp</a>
            <a href="https://web.facebook.com/profile.php?id=100091565948776" target="blank">Facebook</a>
          </div>
       </div>
       <section className="social-media">
        <div className="social-media-wrap">
          <div className='footer-logo'>
            <Link className='social-logo'>
             <i class='fa-solid fa-spray-can-sparkles'></i> AHADI CLEANERS
            </Link>
          </div>
          
        </div>
       </section>
       <small className='website-rights'>Ahadi Cleaners © 2024</small>
    </div>
  );
}

export default Footer;
// Day 2 - 1:30:40